import React from 'react'

import analytics from '@azos/analytics'
import { DownloadProposalType } from '@azos/analytics/src/domain/models'
import { TextProposalStatus } from '@domain/models'
import { useProposalPaymentStatus } from '@presentation/hooks/useProposalPaymentStatus'
import { withProposalPaymentStatus } from '@presentation/providers/ProposalProvider'

import { ProposalCardProduct } from '../ProposalCardProduct'
import { ProposalCardPayment } from './components'
import { ProposalProps } from './ProposalCard.props'

// import { ProductModal } from '../../../Products'
// import Timeline from '../Timeline'

const CardProposal: React.VFC<ProposalProps> = ({
  proposalId,
  number,
  status,
  coverages,
  created,
  premium,
  url,
  frequency,
  // timeline,
}) => {
  const { isPaymentChangeSucceeded, setIsPaymentChangeSucceeded } =
    useProposalPaymentStatus()
  // const [open, setOpen] = React.useState(false)
  const [paymentOpen, setPaymentOpen] = React.useState(false)

  React.useEffect(() => {
    if (isPaymentChangeSucceeded) {
      setPaymentOpen(false)
      setIsPaymentChangeSucceeded(false)
    }
  }, [isPaymentChangeSucceeded, setIsPaymentChangeSucceeded])

  const downloadProposal = () => {
    window.open(url, '_blank')
    analytics.dashboard.policies.downloadProposal.execute({
      type: DownloadProposalType.PROPOSALS,
    })
  }

  return (
    <>
      <ProposalCardProduct
        status={status}
        coverages={coverages}
        created={created}
        premium={premium}
        onClick={downloadProposal}
        onPayment={
          status.label === TextProposalStatus.pending_payment
            ? () => setPaymentOpen(true)
            : undefined
        }
        frequency={frequency}
      />

      <ProposalCardPayment
        open={paymentOpen}
        proposalId={proposalId}
        onClose={() => setPaymentOpen(false)}
      />

      {/* <ProductModal
        open={open}
        onClose={() => setOpen(false)}
        title={`Proposta: ${id}`}
        status={status}
        products={products}
      >
        <Timeline timeline={timeline} />
      </ProductModal> */}
    </>
  )
}

export default withProposalPaymentStatus(CardProposal)
